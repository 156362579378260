import React from 'react'
import { serverResponse } from '../../util/fakeServer';
import { gaEventLabel } from '../../settings/gaConfig';
import { openInteractivityModal } from '../../redux/modal/action';
import { connect } from 'react-redux';
import { getCustomActions, sendAnalyticsEvent } from '../../helper/utility';

const SearchCarouselItem = (props) => {

  return (
    <>
      <div
        style={{ padding: "5%", cursor: "pointer" }}
        onClick={() => {
          if (serverResponse.config.ga_active) {
            sendAnalyticsEvent(
              gaEventLabel.searched_product,
              String(props.element.item_id)
            );
            sendAnalyticsEvent(
              gaEventLabel.open_product,
              String(props.element.item_id)
            );
          }
          props.openInteractivityModal({
            content: getCustomActions().getInteractivity(props.element.item_id),
            info: getCustomActions().getInteractivity(props.element.item_id),
            type: 'plus',
            // elementProps: {
            //   info: props.element,
            //   type: 'plus',
            //   markerInfo: {data: '', type: 'plus', title: null, active: true}
            // }
          });
        }}
      >
        <div className='row' style={{ padding: "5%", width: "100%" }}>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${serverResponse.config.clientIcon}`;
            }}
            style={{ width: "100%", height: "100%" }}
            src={
              props.element.images.length > 0 &&
                props.element.images &&
                props.element.images !== null &&
                props.element.images !== undefined &&
                props.element.images[0].image_file !== undefined &&
                props.element.images[0].hasOwnProperty("image_file")
                ? process.env.PUBLIC_URL +
                props.element.images[0].image_file
                : serverResponse.config.clientIcon
            }
            alt="img"
          />
        </div>
        <div className='row'
          style={{
            padding: "5%",
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <span style={{ textAlign: "center", fontSize: "0.7rem" }}>
            {props.element.field1}{" "}
            {props.element.varieties.length > 0
              ? `- ${props.element.varieties[0]}`
              : null}
          </span>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};

export default connect(mapStateToProps, { openInteractivityModal })(SearchCarouselItem)