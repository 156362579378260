import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import {
  setIndexCarousel,
  isFromInput,
  setVerticalPages
} from "../../redux/carousel/action";
import { getValue, scrollToSlide } from "../../helper/utility";
import { styles } from "./inputPage.style";
import useCurrentWidth from "../../hooks/widthHook";
import { BREAKPOINT } from "../../helper/constant";

const InputPage = (props) => {
  const { carouselIndex } = props;
  const width = useCurrentWidth();
  const [currentId, setCurrentId] = useState(["slide_1"]);
  const [buttonMode, setButtonMode] = useState(
    width < BREAKPOINT.lg &&
      (serverResponse.config.scrollVertical ||
        serverResponse.config.scrollHorizontal)
      ? true
      : false
  );
  const options = { root: null, threshold: 0.6 };
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      let temp_id = [];
      entries
        .filter((el) => el.target.getAttribute("id").includes("slide_"))
        .forEach((el) => {
          if (el.isIntersecting) {
            temp_id.push(el.target.getAttribute("id"));

            setCurrentId(temp_id[0]);
          }
          // console.log(
          //   `I'm ${
          //     el.isIntersecting ? "in view" : "out of view"
          //   }: ${el.target.getAttribute("id")}`,
          //   el.target
          // );
        });
    }, options);

    // Observe all elements with IDs
    const elemsWithIds = document.querySelectorAll("*[id]");
    elemsWithIds.forEach((elem) => observer.observe(elem));
  }, []);

  useEffect(() => {
    // console.log(currentId);
    // props.setIndexCarousel(getValue());
  }, [currentId]);

  const inputPage = useRef();

  function change(event) {
    let target = event.target;
    let input = parseInt(target.value, 10);
    if (event.keyCode === 13) {
      props.isFromInput(true);
      props.setIndexCarousel(getValue(input));
      target.value = "";
      inputPage.current.blur();
      if (
        (serverResponse.config.scrollVertical ||
          serverResponse.config.scrollHorizontal) &&
        width < BREAKPOINT.lg
      ) {
        scrollToSlide(
          input,
          props.setVerticalPages,
          props.carouselIndex.verticalPages
        );
        setButtonMode(true);
      }
    }
  }

  return (
    <Button
      style={styles.buttonDesktop(serverResponse.leaflet.pages.length)}
      className="carousel-pagination-button"
      color="primary"
      active={false}
      onClick={() => {
        setButtonMode(false);
      }}
    >
      {/* {currentId} */}
      {!buttonMode ? (
        <span
          style={{
            fontSize: "16px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <input
            onFocus={(e) => {
              if (
                (!serverResponse.config.scrollVertical ||
                  width >= BREAKPOINT.lg) &&
                inputPage.current !== undefined
              ) {
                inputPage.current.classList.add("onFocus");
              }

              e.target.placeholder = "";
            }}
            onBlur={(e) => (e.target.placeholder = carouselIndex.labelIndex)}
            id="input-page"
            ref={inputPage}
            type="text"
            autoFocus={
              serverResponse.config.scrollVertical && width < BREAKPOINT.lg
                ? true
                : false
            }
            onClick={(e) => e.target.select()}
            style={styles.inputDesktop}
            onKeyDown={(e) => change(e)}
            placeholder={
              // serverResponse.config.scrollVertical && width < BREAKPOINT.lg
              //   ? currentId.replace(/slide_/g, "")
              //   :
              carouselIndex.labelIndex
            }
          />
          <span>/</span>
          <span style={{ marginLeft: 3 }}>
            {serverResponse.leaflet.pages.length}
          </span>
        </span>
      ) : (
        <p>Vai a</p>
      )}
    </Button>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps, {
  setIndexCarousel,
  isFromInput,
  setVerticalPages
})(InputPage);
