import React from "react";
import { serverResponse } from "../../util/fakeServer";

const Loader = ({
  width = "5rem",
  height = "5rem",
  background = serverResponse.config.background_color,
  single,
  containerWidth = "100vw",
  containerHeight = "100vh",
  title = ""
}) => {
  const style = {
    background: background,
    width: containerWidth,
    height: containerHeight,
    zIndex: "10",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(!single && {
      position: "fixed",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)"
    })
  };

  return (
    <>
      <div style={style}>
        <div
          className="spinner-grow"
          style={{
            width: width,
            height: height,
            color: serverResponse.config.secondary_color
          }}
          role="status"
        />
        {title !== "" && (
          <h3
            style={{
              left: "50%",
              top: "60%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              color: "white"
            }}
          >
            {title}
          </h3>
        )}
      </div>
    </>
  );
};

export default Loader;
