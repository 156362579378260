import React from "react";
import { connect } from "react-redux";
import { H_COMPONENTS } from "../../helper/constant";
import { setStatusOfMenu } from "../../redux/floatingMenu/action";
export const AB = (p) => (
  <button type="button" {...p} className="rtf--ab">
    {p.children}
  </button>
);

export const MB = (p) => (
  <button type="button" className="rtf--mb" {...p}>
    {p.children}
  </button>
);

const Fab = ({
  event = "click",
  position = {
    left: 10,
    bottom:
      !window.location.pathname.includes("leaflet") &&
      !window.location.pathname.includes("pages")
        ? 0
        : H_COMPONENTS.adsense
  },
  alwaysShowTitle = false,
  children,
  icon,
  mainButtonStyles,
  floatingMenu
}) => {
  const open = () => setStatusOfMenu(true);
  const close = () => setStatusOfMenu(false);
  const enter = () => event === "hover" && open();
  const leave = () => event === "hover" && close();
  const toggle = () =>
    event === "click" ? (floatingMenu ? close() : open()) : null;

  const actionOnClick = (userFunc) => {
    setStatusOfMenu(false);

    setTimeout(() => {
      userFunc();
    }, 1);
  };

  const rc = () => {
    // if (React.Children.count(children) > 6)
    //   console.warn("react-tiny-fab only supports up to 6 action buttons");
    const ariaHidden = alwaysShowTitle || !floatingMenu;

    return React.Children.map(
      children,
      (ch, i) =>
        ch && (
          <li className={`rtf--ab__c ${"top" in position ? "top" : ""}`}>
            {React.cloneElement(ch, {
              "data-testid": `action-button-${i}`,
              "aria-label": ch.props.text || `Menu button ${i + 1}`,
              "aria-hidden": ariaHidden,
              ...ch.props,
              onClick: () => actionOnClick(ch.props.onClick)
            })}
            {ch.props.text && (
              <span
                onClick={() => actionOnClick(ch.props.onClick)}
                className={`${"right" in position ? "right" : ""} ${
                  alwaysShowTitle ? "always-show" : ""
                }`}
                aria-hidden={ariaHidden}
              >
                {ch.props.text}
              </span>
            )}
          </li>
        )
    );
  };

  return (
    <ul
      onMouseEnter={enter}
      onMouseLeave={leave}
      className={`rtf ${floatingMenu ? "open" : "closed"}`}
      data-testid="fab"
      style={position}
    >
      <li className="rtf--mb__c">
        <MB
          onClick={toggle}
          style={mainButtonStyles}
          data-testid="main-button"
          role="button"
          aria-label="Floating menu"
          tabIndex="0"
        >
          <i className={icon}></i>
        </MB>
        <ul>{rc()}</ul>
      </li>
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    floatingMenu: state.floatingMenu.isOpen
  };
};

const mapDispatchToProps = { setStatusOfMenu };

export default connect(mapStateToProps, mapDispatchToProps)(Fab);
