import React from "react";

const StoreModal = (props) => {

  return (
    <a className="btn btn-primary d-flex justify-content-center align-items-center" href={props.link} target="_blank" rel="noopener noreferrer">
      <svg width={25} height={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="white" d="M547.6 103.8L480 0H96L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384 512H512V384 252.6c-4 1-8 1.8-12.3 2.3z" /></svg>
      <span className="ml-2">Vai allo store</span> </a>
  );
};

export default StoreModal;
