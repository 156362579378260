import React, { Component } from "react";

import { CustomInput } from "reactstrap";
import { Textfit } from "react-textfit";
import { isMobile } from "react-device-detect";
import { iconDemo } from "../../helper/constant";
class MyGroceryElement extends Component {
  render() {
    return (
      <div
        id={"product_" + this.props.info.index}
        className="container list-container"
        style={{
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          backgroundColor: "#FFFFFF"
        }}
      >
        <div className="row ptb-10">
          <div className="col-1" style={centerStyle}>
            <CustomInput
              type="checkbox"
              id={this.props.info.index}
              onChange={this.props.checkProduct}
              checked={this.props.info.done}
            />
          </div>
          <div className={isMobile ? "col-3" : "col-2"} style={centerStyle}>
            <img
              src={process.env.PUBLIC_URL + this.props.info.image_file}
              alt="img"
              style={{ width: "100%" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = iconDemo;
              }}
            />
          </div>
          <div className={isMobile ? "col-5" : "col-6"} style={fieldsStyle}>
            <Textfit mode="multi">
              <span style={{ textTransform: "uppercase" }}>
                <span style={{ fontWeight: "bold" }}>
                  {this.props.info.field1}{" "}
                </span>{" "}
                <br />
                {this.props.info.field2} {this.props.info.field3}{" "}
                {this.props.info.field4}
                {this.props.info.variety && (
                  <span>
                    <br />({this.props.info.variety})
                  </span>
                )}
                <br /> {this.props.info.currentPrice > 0 && this.props.info.currentPrice + '€'}
              </span>
            </Textfit>
          </div>
          <div className="col-3" style={centerStyle}>
            <Textfit mode="single" max={15}>
              <span style={{ textTransform: "lowercase" }}>
                {this.props.info.quantity} {this.props.info.grocery_label}{" "}
              </span>
            </Textfit>
          </div>
        </div>
      </div>
    );
  }
}

const fieldsStyle = {
  display: "flex",
  alignItems: "center"
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "0",
  paddingRight: "0"
};

export default MyGroceryElement;
