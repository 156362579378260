import React, { useState } from "react";

const CookieConsentBannerIP = () => {
  const [clicked, setClicked] = useState(false);

  if (clicked) return null;

  return (
    <div
      className=""
      style={{
        position: "absolute",
        bottom: 0,
        width: "100vw",
        height: "100vh",
        margin: "auto",
        backgroundColor: "rgba(90, 197, 243, 0.5)",
        right: 0,
        left: 0,
        zIndex: 11
      }}
    >
      <div
        className="container"
        style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
      >
        <div className="row">
          <div
            className="col-12 p-5"
            style={{
              backgroundColor: "rgb(90, 197, 243)",
              position: "relative",
              bottom: 0
            }}
          >
            <p>
              Questo sito fa uso di cookie per migliorare l’esperienza di
              navigazione degli utenti e per raccogliere informazioni
              sull’utilizzo del sito stesso. Utilizziamo sia cookie tecnici sia
              cookie di parti terze per inviare messaggi promozionali sulla base
              dei comportamenti degli utenti. Può conoscere i dettagli
              consultando la nostra
              <a
                style={{ color: "#ffdd00", fontWeight: 700 }}
                href="https://www.iperbimbo.it/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                privacy policy qui
              </a>
              . Proseguendo nella navigazione si accetta l’uso dei cookie; in
              caso contrario è possibile abbandonare il sito.
            </p>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  className="btn"
                  style={{ backgroundColor: "#ffdd00" }}
                  onClick={() => {
                    const video_player = document.getElementById(
                      "video_on_page"
                    );
                    if (video_player !== null) {
                      video_player.play();
                    }
                    setClicked(true);
                  }}
                >
                  Continua
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBannerIP;
