import React from "react";
import { Player } from "video-react";

const VideoModal = ({ data }) => {
  const player =
    data.video_type === "video_file" ? (
      <div className="col-12 col-md-8 m-auto">
        <Player
          autoPlay={true}
          playsInline
          src={process.env.PUBLIC_URL + data.link}
        />
      </div>
    ) : (
      <div
        className="video-responsive"
        style={{ height: "50vh", width: "100%" }}
      >
        <iframe
          title={data.link}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${data.link}?autoplay=1&mute=1`}
        />
      </div>
    );

  return <>{player}</>;
};

export default VideoModal;
