import React, { useEffect } from "react";
import CustomCarousel from "../leaflet/CustomCarousel";
import useCurrentWidth from "../../hooks/widthHook";
import { serverResponse } from "../../util/fakeServer";
import { BREAKPOINT } from "../../helper/constant";
import CustomCarouselVertical from "../leaflet/CustomCarouselVertical";
import ScrollListener from "../ScrollListener/ScrollListener";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../redux/carousel/action";
import CustomCarouselHorizontal from "../leaflet/CustomCarouselHorizontal";
import ScrollListenerHorizontal from "../ScrollListener/ScrollListenerHorizontal";

const CarouselWrapper = (props) => {
  const width = useCurrentWidth();

  const options = { root: null, threshold: 0.6 };

  const delay = (index) => (index > 0 ? 0.07 * (index / 2 + 1) : 0);

  useEffect(() => {
    if (width < BREAKPOINT.lg) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((el, index) => {
          el.target.style.animationDelay = `${delay(index)}s`;
          el.target.classList.toggle("fadeIn_interact", el.isIntersecting);
        });
      }, options);
      // Observe all elements with IDs
      const elemsWithIds = document.querySelectorAll("[type=fadeAnimationInt]");

      elemsWithIds.forEach((elem) => observer.observe(elem));
    }
  }, [props.carouselIndex.verticalPages, props.carouselIndex.carouselIndex]);

  if (
    (serverResponse.config.scrollVertical ||
      serverResponse.config.scrollHorizontal) &&
    width < BREAKPOINT.lg
  ) {
    if (serverResponse.config.scrollHorizontal) {
      return (
        <ScrollListenerHorizontal>
          <CustomCarouselHorizontal
            leaflet={props.leaflet}
            setPageIndex={props.setPageIndex}
            itemId={props.itemId}
          />
        </ScrollListenerHorizontal>
      );
    }
    return (
      <ScrollListener>
        <CustomCarouselVertical
          leaflet={props.leaflet}
          setPageIndex={props.setPageIndex}
          itemId={props.itemId}
        />
      </ScrollListener>
    );
  }
  return (
    <CustomCarousel
      leaflet={props.leaflet}
      setPageIndex={props.setPageIndex}
      itemId={props.itemId}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};
export default connect(mapStateToProps, { setIndexCarousel })(CarouselWrapper);
