import React from 'react'

const UnderPriceImg = ({ underPriceImage }) => {
    return (
        <img
            style={{ width: "50%", marginTop: "10px" }}
            src={process.env.PUBLIC_URL + underPriceImage}
            alt="img"
        />
    )
}

export default UnderPriceImg