export const styles = {
  icon: {
    color: "#00ff00",
    marginLeft: "20px",
    width: "30px",
    cursor: "pointer"
  },
  confirm: {
    color: "#212529",
    backgroundColor: "#f8f9fa",
    borderColor: "#f8f9fa"
  },
  abort: (color) => {
    return {
      color: "white",
      backgroundColor: color,
      borderColor: color
    };
  },
  tooltip: (color = "#6f747a") => {
    return {
      position: "absolute",
      borderRadius: "0.25rem",
      width: "100px",
      padding: "2px 0",
      textAlign: "center",
      backgroundColor: color,
      bottom: "50px",
      zIndex: 1,
      border: "1px solid white",
      right: "2px"
    };
  }
};
