import React, { useEffect, useState } from "react";
import { serverResponse } from "../../../../util/fakeServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import { strings } from "../../../../util/data/strings";
import { connect } from "react-redux";
import actions from "../../../../redux/groceryList/action";
import { isModalOpen } from "../../../../redux/carousel/action";
import { NotificationManager } from "react-notifications";
import { language } from "../../../../languageProvider/language/language";
import {
  defaultLanguage,
  sendAnalyticsEvent
} from "../../../../helper/utility";
import { gaEventLabel } from "../../../../settings/gaConfig";
import AddIcon from "../../../common/AddIcon";

const CartModal = (props) => {
  const [quantity, setQuantity] = useState(
    props.info.quantity_step ? props.info.quantity_step : 1
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    props.setVarietySelected(
      props.info.varieties.length > 0 ? props.info.varieties[0] : ""
    );
  }, [props.info]);

  const incrementQty = () => {
    setQuantity(
      (prev) => prev + (props.info.quantity_step ? props.info.quantity_step : 1)
    );
  };

  const decrementQty = () => {
    if (props.info.quantity_step) {
      if (quantity > props.info.quantity_step) {
        setQuantity((prev) => prev - props.info.quantity_step);
      }
    } else if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const toggleSaveTooltip = () => {
    setIsOpen(!isOpen);
  };

  const handleAddProduct = () => {
    let obj = Object.assign({}, props.info);
    obj.quantity = quantity;
    obj.done = false;
    obj.variety = props.varietySelected;
    props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );
    if (document.getElementById("selected-" + props.info.id) != null)
      document.getElementById("selected-" + props.info.item_id).style[
        "display"
      ] = "inline";
    sendAnalyticsEvent(
      gaEventLabel.add_to_grocery_list,
      String(props.info.item_id)
    );
  };

  return (
    <div className={`d-flex ${props.customClassContainer}`}>
      <div className={`${props.colCustom || "col-12"} `}>
        <div
          className="container-add-to-list"
          style={{ justifyContent: props.justifyContent || "space-between" }}
        >
          {!props.info.one_and_one_gratis && (
            <div className="container-add-to-list">
              <FontAwesomeIcon
                className="change-product-quantity-icon"
                icon={faMinusCircle}
                onClick={decrementQty}
              />
              <h6>{quantity}</h6>
              <h6 style={{ fontWeight: "bold" }}>{props.info.grocery_label}</h6>
              <FontAwesomeIcon
                className="change-product-quantity-icon"
                icon={faPlusCircle}
                onClick={incrementQty}
              />
            </div>
          )}
          {!props.info.one_and_one_gratis && (
            <div className="container-add-to-list">
              <AddIcon
                // backgroundColor={"#c7c7c7"}
                id={"btnSave_" + props.info.item_id}
                disabled={
                  props.info.stock !== "y" &&
                  props.info.stock !== null &&
                  serverResponse.config.manager_stock
                }
                color="primary"
                onClick={handleAddProduct}
                width={35}
              />

              {props.info.stock === "t" && (
                <Tooltip
                  placement="top"
                  isOpen={this.state.saveTooltipOpen}
                  target={"btnSave_" + props.info.item_id}
                  toggle={toggleSaveTooltip}
                >
                  {strings.saveBtnTooltip}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
      {props.componentPrice}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    isModalOpen: (bool) => {
      dispatch(isModalOpen(bool));
    }
  };
};

export default connect(null, mapDispatchToProps)(CartModal);
