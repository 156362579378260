// CAROUSEL
export const SET_CAROUSEL_INDEX = "SET_CAROUSEL_INDEX";
export const CAROUSEL_LOADING = "CAROUSEL_LOADING";
export const SET_INDEX_LABEL = "SET_INDEX_LABEL";
export const SET_SLIDE_MAX = "SET_SLIDE_MAX";
export const IS_FROM_INPUT = "IS_FROM_INPUT";
export const SET_FIRST_LOAD = "SET_FIRST_LOAD";
export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL";
export const SET_REF_ZOOM = "SET_REF_ZOOM";
export const SET_BUTTON_DISABLED = "SET_BUTTON_DISABLED";
export const IS_MODAL_OPEN = "IS_MODAL_OPEN";
export const IS_RIGHT_SIDEBAR_OPEN = "IS_RIGHT_SIDEBAR_OPEN";
export const SET_VERTICAL_PAGES = "SET_VERTICAL_PAGES";
//COOKIE
export const SET_COOKIE_INFO = "SET_COOKIE_INFO";

//FLOATING MENU
export const IS_MENU_OPEN = "IS_MENU_OPEN";

//VIDEO MANAGER
export const SET_VISIBILITY_VIDEO = "SET_VISIBILITY_VIDEO";
//MODAL
export const OPEN_INTERACTIVITY_MODAL = "OPEN_INTERACTIVITY_MODAL";
export const CLOSE_INTERACTIVITY_MODAL = "CLOSE_INTERACTIVITY_MODAL";
export const OPEN_RELATED = "OPEN_RELATED";

// // const persona1 = new Person()
// Person.name
