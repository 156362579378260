import React from "react";
import { connect } from "react-redux";
import VideoModal from "./components/Video.modal";

const PlayBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { data }
    }
  } = modalRedux;

  return <VideoModal data={data} />;
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};

export default connect(mapStateToProps)(PlayBody);
