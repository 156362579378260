import React from "react";

const TitleModal = ({ title, fontSize = "30px" }) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: title
      }}
      style={{
        fontSize: fontSize,
        textTransform: "uppercase",
        fontWeight: "bold",
        overflowWrap: "break-word"
      }}
    />
  );
};

export default TitleModal;
