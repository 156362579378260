import { SET_VISIBILITY_VIDEO } from "../type";

const initState = {
    videoShow: true
}

export default function (state = initState, action) {
    switch (action.type) {
        case SET_VISIBILITY_VIDEO:
            return {
                ...state,
                videoShow: !state.videoShow
            } 
        default:
            return state;
    }
}
