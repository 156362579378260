import React from "react";
import { connect } from "react-redux";
import { getCustomActions } from "../../../../helper/utility";
import { openRelated } from "../../../../redux/modal/action";
import { strings } from "../../../../util/data/strings";
import RelatedProduct from "../../../leaflet/RelatedProduct";

const Related = (props) => {
  const handleRelatedClick = (item_id) => {
    props.openRelated({
      content:getCustomActions().getInteractivity(item_id),

    })
   
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <span>{props.related.length > 0 ? strings.relatedProductsTitle : ""}</span>
        </div>
      </div>
      <div className="row">
        {props.related.map((el) => (
          <RelatedProduct
            key={el}
            prod={getCustomActions().getInteractivity(el)}
            handleClick={handleRelatedClick}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};


export default connect(mapStateToProps,{openRelated})(Related);
