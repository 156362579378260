import React from "react";
import { serverResponse } from "../../util/fakeServer";

const AddIcon = ({ width, backgroundColor = serverResponse.config.background_color, onClick, id = '' }) => {
  const green = serverResponse.config.secondary_color;

  const renderIcon = serverResponse.config.send_grocery_list_to_market ? (
    <svg
      id={id}
      style={{ width: `${width}px` }}
      fill={serverResponse.config.primary_color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 324.36 264"
    >
      <g id="Livello_2" data-name="Livello 2">
        <g id="Livello_1-2" data-name="Livello 1">
          <path
            fill={green}
            d="M230.74,82.84H204.25V60.76a8.83,8.83,0,0,0-8.83-8.83h-8.83a8.84,8.84,0,0,0-8.83,8.83V82.84H151.28a8.83,8.83,0,0,0-8.83,8.83v8.83a8.82,8.82,0,0,0,8.83,8.82h26.48V131.4a8.83,8.83,0,0,0,8.83,8.83h8.83a8.83,8.83,0,0,0,8.83-8.83V109.32h26.49a8.82,8.82,0,0,0,8.83-8.82V91.67A8.83,8.83,0,0,0,230.74,82.84Z"
          />
          <circle cx="116.38" cy="237.93" r="26.07" />
          <circle cx="273.26" cy="237.93" r="26.07" />
          <path d="M321.58,25.8a12.76,12.76,0,0,0-10.07-4.87H72.41l-1.7-8A16.46,16.46,0,0,0,54.7,0H5.52a5.52,5.52,0,0,0,0,11H54.7a5.35,5.35,0,0,1,5.21,4.22L97.3,190.48a16.47,16.47,0,0,0,16,13h174a5.52,5.52,0,1,0,0-11h-174a5.35,5.35,0,0,1-5.21-4.22l-3.62-16.94h175a16.27,16.27,0,0,0,15.91-12.61L324,36.71A12.78,12.78,0,0,0,321.58,25.8Zm-8.31,8.39L284.66,156.1a5.29,5.29,0,0,1-5.17,4.09H103.41L77.47,32h234a1.8,1.8,0,0,1,1.76,2.22Z" />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      style={{ width: `${width}px` }}
      xmlns="http://www.w3.org/2000/svg"
      fill={serverResponse.config.primary_color}
      viewBox="0 0 226.94 290"
    >
      <g id="Livello_2" data-name="Livello 2">
        <g id="Livello_1-2" data-name="Livello 1">
          <path
            fill={green}
            d="M221.23,24.18l-19.06.22C202.11,19,202,13.67,202,8.3c-.05-4.4-.79-8.48-6.21-8.29-5.21.18-5.3,4.39-5.25,8.39.08,5.38.13,10.76.2,16.14l-16.22.19c-4.4,0-8.48.79-8.29,6.2.18,5.22,4.4,5.31,8.39,5.25,5.42-.08,10.84-.13,16.27-.2.07,6.38.18,12.76.23,19.14a5.63,5.63,0,0,0,5.22,5.58h.12A5.66,5.66,0,0,0,202.53,55q-.09-9.59-.22-19.18c6.34-.08,12.68-.18,19-.24a5.63,5.63,0,0,0,5.58-5.22.41.41,0,0,0,0-.11A5.66,5.66,0,0,0,221.23,24.18Z"
          />
          <rect x="63.64" y="126.19" width="92.8" height="15.41" rx="5.24" />
          <rect x="63.64" y="160.87" width="92.8" height="15.41" rx="5.24" />
          <rect x="63.64" y="193.62" width="92.8" height="15.41" rx="5.24" />
          <circle cx="40.6" cy="133.89" r="8.99" />
          <circle cx="40.6" cy="169.22" r="8.99" />
          <circle cx="40.6" cy="202.61" r="8.99" />
          <path d="M196.57,88.85,139.21,30.94H18.94A19,19,0,0,0,0,49.88V254.62c0,14,0,22.39,4.89,28.09,5.24,6.13,14.76,7.29,30.49,7.29h142a19,19,0,0,0,18.93-18.94Zm-8.28,182.21A11,11,0,0,1,177.36,282h-142c-14.8,0-21.65-1.26-24.42-4.49-3-3.46-3-11.19-3-22.89V49.88A11,11,0,0,1,18.94,38.94H131.22v58.5h57.07Z" />
        </g>
      </g>
    </svg>
  );
  return (
    <div
      className="btn d-flex justify-content-center align-items-center"
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      {renderIcon}
    </div>
  );
};

export default AddIcon;
