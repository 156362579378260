import React, { useRef, useState, useEffect } from "react";
import CustomProduct from "./CustomProduct";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { BREAKPOINT, H_COMPONENTS } from "../../helper/constant";
import { isMobileSafari } from "react-device-detect";
import ReactDOM from "react-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import Loader from "../common/Loader";
import { animationType, getCustomActions } from "../../helper/utility";
import useCurrentWidth from "../../hooks/widthHook";

const CustomSlide = (props) => {
  const [loadingImages, setLoadingImages] = useState();
  const width = useCurrentWidth();

  const listProducts = props.products.map((element, key) => {
    return (
      <CustomProduct
        zoom={props.zoom}
        disableSwipe={props.disableSwipe}
        enableSwipe={props.enableSwipe}
        key={key}
        blueprint={element.blueprint}
        info={getCustomActions().getInteractivity(element.item_id)}
        leafletSwipe={props.leafletSwipe}
        isSwipeDisabed={props.isSwipeDisabed}
        setSwipe={props.setSwipe}
      />
    );
  });

  const imageRef = useRef(null);
  var interval = 0;
  const isImageLoad = () => {
    var element = ReactDOM.findDOMNode(imageRef.current);

    if (element !== null && element.offsetWidth > 0) {
      clearInterval(interval);

      setLoadingImages(false);
    } else {
      setLoadingImages(true);
    }
  };

  useEffect(() => {
    interval = setInterval(isImageLoad, 300);
  }, []);

  const safariDetectMobile = isMobileSafari ? 30 : 0;

  return (
    <div
      id={`slide_${props.pageNumber}`}
      className={`${
        serverResponse.config.scrollVertical && width < BREAKPOINT.lg
          ? "mt-2 mb-2 d-flex p-0 flex-column justify-content-center align-items-center bordo"
          : "d-flex p-0 flex-column justify-content-center align-items-center bordo"
      } `}
    >
      {loadingImages && <Loader single={props.single} />}
      <div style={{ position: "relative" }}>
        <div className={`shadow-${props.shadow}`}></div>

        <LazyLoadImage
          ref={imageRef}
          alt=""
          src={props.imagePath + "?v=" + serverResponse.config.version}
          placeholderSrc={
            props.imageThumbPath + "?v=" + serverResponse.config.version
          }
          visibleByDefault={true}
          className={`img-fluid ${animationType(props.animationType)}`}
          style={{
            maxHeight: serverResponse.config.adsense
              ? `calc(100vh - ${H_COMPONENTS.header +
                  H_COMPONENTS.adsense +
                  H_COMPONENTS.pager +
                  safariDetectMobile}px)`
              : `calc(100vh - ${H_COMPONENTS.header +
                  H_COMPONENTS.pager +
                  safariDetectMobile}px)`
          }}
        />

        {listProducts}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps)(CustomSlide);
