import React from "react";
import CookieConsentBannerIP from "../components/leaflet/CookieConsentBannerIP";
import { plusIconIP, playIconIP } from "../helper/constant";
import DefaultManager from "./Default.manager";

class IperbimboManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getPlusIcon() {
    return plusIconIP;
  }

  getPlayIcon() {
    return playIconIP;
  }

  getMarkerIconClass() {
    return "iperBimboMarker";
  }
  getConsentBanner() {
    return <CookieConsentBannerIP />;
  }

  getVideoProps() {
    return {
      muted: false,
      controls: true
    };
  }
}

export default IperbimboManager;
