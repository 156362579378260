import React from "react";
import AdComponent from "../components/leaflet/Adsense";
import CookieConsentBanner from "../components/leaflet/CookieConsentBanner";
import { plusIcon, playIcon } from "../helper/constant";
import { serverResponse } from "../util/fakeServer";

class DefaultManager {
  signboardId;

  constructor(signboardId) {
    this.signboardId = signboardId;
  }

  getPlusIcon() {
    return plusIcon;
  }

  getPlayIcon() {
    return playIcon;
  }

  getMarkerIconClass() {
    return "";
  }

  getConsentBanner() {
    return <CookieConsentBanner />;
  }

  getVideoProps() {
    return {
      muted: true,
      controls: false
    };
  }

  getHAdsense() {
    return 90;
  }

  getBannerComponent() {
    return <AdComponent />;
  }

  filterMarkers() {
    return null;
  }

  getStoreButton(link) {
    return null;
  }

  getRowClass(type) {
    return "";
  }

  getAnalyticsId() {
    return serverResponse.config.ga_tracking_id;
  }
}

export default DefaultManager;
