import React from "react";
import ManagerFactory from "../../../managers/ManagerFactory";
const manager = ManagerFactory.getManager();

const videoProps = manager.getVideoProps();

const VideoInteractivity = (props) => {
  if (props.markerInfo.data.video_type === "youtube") {
    return (
      <iframe
        title={props.markerInfo.data.link}
        width="100%"
        height="100%"
        src={
          "https://www.youtube.com/embed/" +
          props.markerInfo.data.link +
          "?autoplay=1&mute=1"
        }
      />
    );
  }
  return (
    <div
      id="videoIper"
      style={{
        height: "100%"
        // width: "100%",
        // justifyContent: "center",
        // alignItems: "center"
      }}
    >
      <video
        id="video_on_page"
        autoPlay
        defaultmuted="true"
        muted={videoProps.muted}
        controls={videoProps.controls}
        playsInline
        loop
        style={{ width: "100%" }}
      >
        <source src={props.markerInfo.data.link} />
      </video>

      {/* <VideoStream /> */}
    </div>
  );
};

export default VideoInteractivity;
