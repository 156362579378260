import React, { Component } from "react";
import PropTypes from "prop-types";
import { H_COMPONENTS } from "../../helper/constant";
const googleAdId = "ca-pub-5126789034157520";
class AdComponent extends Component {
  googleInit = null;
  componentDidMount() {
    const { timeout } = this.props;
    this.googleInit = setTimeout(() => {
      if (typeof window !== "undefined")
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, timeout);
  }
  componentWillUnmount() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }
  render() {
    const { position, bannerH } = this.props;
    return (
      <div
        id="adsense_container"
        style={{
          height: `${bannerH ? bannerH : H_COMPONENTS.adsense}px`,
          width: "100%",
          position: position,
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <ins className="adsbygoogle"
          style={{
            display: "flex",
            height: `${bannerH ? bannerH : H_COMPONENTS.adsense}px`,
            width: "100%",
            justifyContent: "center"
          }}
          data-ad-client={googleAdId}
          // data-ad-slot="6959717873"
          // data-ad-format="auto"
          data-full-width-responsive={bannerH !== 90 ? "true" : "false"}
        ></ins>
      </div>
    );
  }
}
AdComponent.propTypes = {
  timeout: PropTypes.number,
  position: PropTypes.string
};
AdComponent.defaultProps = {
  timeout: 350,
  position: "inherit"
};
export default AdComponent;