import React from "react";

const TextModal = ({ text1, text2, varieties = [] }) => {

  return (
    <>
      <p style={{ textTransform: "uppercase" }}>
        {text1}
        <span style={{ fontSize: "15", fontWeight: "900" }}>
          {varieties.length === 1 &&
            ` - ${varieties[0]}`}
        </span>
      </p>
      {
        text2 !== undefined &&
        <p style={{ textTransform: "uppercase" }}>
          {text2}
        </p>
      }
    </>
  );
};

export default TextModal;
