import React, { Component, createRef } from "react";
import { Button } from "reactstrap";
import CustomSlide from "./CustomSlide";
import CustomIndex from "./CustomIndex";
import { isIE, isIOS, isMobile } from "react-device-detect";
import "../../assets/css/custom.css";
import { serverResponse } from "../../util/fakeServer";
import ReactGA from "react-ga";
import { gaEventLabel } from "../../settings/gaConfig";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import PrismaZoom from "react-prismazoom";
import Adsense from "./Adsense";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import { connect } from "react-redux";
import {
  setIndexCarousel,
  setIndexLabel,
  setSlideMax,
  isFromInput,
  setFirstLoad,
  setZoomLevel,
  setRefZoom,
  setButtonDisabled,
  setVerticalPages
} from "../../redux/carousel/action";
import { BREAKPOINT } from "../../helper/constant";
import {
  checkMobileOs,
  gAInitializer,
  isSupported,
  sendAnalyticsEvent
} from "../../helper/utility";
import { styles } from "./customCarousel.styles";

if (!isMobile) {
  import("./scrollbar.css").then((module) => {});
}

const {
  config: { adsense, type, show_one_page, ga_active },
  leaflet: { pages, index: serverIndex },
  video
} = serverResponse;

let videoModalStyles = {
  content: {
    background: "transparent",
    border: 0
  },
  overlay: {
    zIndex: 999999999
  }
};

if (window.innerWidth < BREAKPOINT.lg) {
  videoModalStyles = {
    content: {
      background: "transparent",
      border: 0,
      padding: 0,
      top: "10px",
      left: "10px",
      bottom: "10px",
      right: "10px"
    },
    overlay: {
      zIndex: 999999999
    }
  };
}

const showOnePage = show_one_page || window.innerWidth < BREAKPOINT.lg;
const hasIndex_ = serverIndex != null;

var thumbs = [];
if (hasIndex_) {
  thumbs.push(serverIndex.image_file);
}
pages.map((e) => thumbs.push(e.thumb_image_file));

class CustomCarouselVertical extends Component {
  constructor(props) {
    super(props);
    var t_items = 10;
    var t_pages = [];
    serverResponse.leaflet.pages.map((element, key) => {
      if (key < t_items) t_pages.push(element.thumb_image_file);
      return 0;
    });

    let leafleat_has_video = false;
    let pages_with_video = null;
    let video_only_once = false;
    let video_mandatory_seconds = 0;
    let level_resize = adsense ? 200 : 105;
    let global_heigth = window.innerHeight - level_resize;
    let global_width = window.innerWidth;

    this.prismaZoom = createRef();

    if (serverResponse.hasOwnProperty("video")) {
      leafleat_has_video = true;
      pages_with_video = video.videos.map((i) => i.page);
      video_only_once = video.show_only_once;
      video_mandatory_seconds = video.mandatory_seconds;
    }

    this.state = {
      loaded: false,
      slideIndex:
        type === "menu" ? 0 : window.innerWidth < BREAKPOINT.lg ? 1 : 0,
      swipe: true,
      video_url: "",
      video_muted: true,
      video_playing: false,
      video_modal_open: false,
      leafleat_has_video: leafleat_has_video,
      pages_with_video: pages_with_video,
      show_close_video: false,
      preroll_video_in_progress: false,
      video_only_once: video_only_once,
      video_page_number: null,
      video_page_item_id: null,
      countdown: video_mandatory_seconds,
      video_mandatory_seconds: video_mandatory_seconds,
      isZoom: false,
      sliderValue:
        type === "menu" ? 0 : window.innerWidth < BREAKPOINT.lg ? 1 : 0,
      fromSlider: false,
      tmpIndex: props.carouselIndex.carouselIndex,
      buttonDisable: false,
      globalHeigth: global_heigth,
      globalWidth: global_width,
      closeClick: false,
      isCurrentOnePage: show_one_page,
      pages_slide: t_pages,
      items: t_items
    };

    this.closeVideoModal = this.closeVideoModal.bind(this);
    this.onVideoProgress = this.onVideoProgress.bind(this);
    this.onVideoStart = this.onVideoStart.bind(this);
    this.toggleVideoMuted = this.toggleVideoMuted.bind(this);
    this.showPageVideo = this.showPageVideo.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.userCloseVideo = this.userCloseVideo.bind(this);
    this.resize = this.resize.bind(this);
  }

  thumbs = pages.map((e) => e.thumb_image_file);

  ref = (player) => {
    this.player = player;
  };

  toggleVideoMuted() {
    this.setState({ video_muted: !this.state.video_muted });
  }

  onVideoEnded() {
    this.closeVideoModal();
    if (ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.client_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.page_video_completion,
        label: String(this.state.video_page_item_id)
      });
    }
  }

  userCloseVideo() {
    this.closeVideoModal();
    if (ga_active) {
      ReactGA.event({
        category: `${serverResponse.config.client_id}-${serverResponse.leaflet.id}`,
        action: gaEventLabel.page_video_closed_by_user,
        label: String(this.state.video_page_item_id)
      });
    }
  }

  closeVideoModal() {
    this.setState({
      video_modal_open: false,
      video_url: "",
      preroll_video_in_progress: false,
      show_close_video: false,
      closeClick: true
    });
  }

  showPageVideo(page_number, onload = false) {
    if (this.state.video_only_once) {
      let session_video_pages = sessionStorage.getItem("video_pages");
      if (session_video_pages == null) {
        sessionStorage.setItem("video_pages", JSON.stringify([page_number]));
      } else {
        let session_video_pages_parsed = JSON.parse(session_video_pages);
        if (session_video_pages_parsed.includes(page_number)) {
          return;
        } else {
          session_video_pages_parsed.push(page_number);
          sessionStorage.setItem(
            "video_pages",
            JSON.stringify(session_video_pages_parsed)
          );
        }
      }
    }

    this.setState(
      {
        video_modal_open: true,
        video_url:
          process.env.PUBLIC_URL +
          video.videos.filter((i) => i.page === page_number)[0].url,
        video_playing: true,
        video_muted: onload ? true : isIOS,
        video_page_number: page_number,
        video_page_item_id: video.videos.filter(
          (i) => i.page === page_number
        )[0].item_id,
        countdown: this.state.video_mandatory_seconds
      },
      () => {
        if (ga_active) {
          ReactGA.event({
            category: `${serverResponse.config.client_id}-${serverResponse.leaflet.id}`,
            action: gaEventLabel.page_video_play,
            label: String(this.state.video_page_item_id)
          });
        }
      }
    );
  }

  onVideoProgress(args) {
    if (this.state.preroll_video_in_progress) {
      this.setState({
        countdown: parseInt(this.state.countdown - 1)
      });
      if (args.playedSeconds > this.state.video_mandatory_seconds) {
        this.setState({
          preroll_video_in_progress: false,
          show_close_video: true
        });
        if (ga_active) {
          ReactGA.event({
            category: `${serverResponse.config.client_id}-${serverResponse.leaflet.id}`,
            action: gaEventLabel.page_video_mandatory_completed,
            label: String(this.state.video_page_item_id)
          });
        }
      }
    }
  }

  onVideoStart() {
    this.setState({
      preroll_video_in_progress: true
    });
  }

  goToPage = (index) => {
    this.setState(
      {
        slideIndex: index,
        tmpIndex: index
      },
      () => {
        this.setState(this.state);
      }
    );
  };

  disableSwipe = () => this.setState({ swipe: !this.state.swipe });

  setSwipe = (swipe) => {
    this.setState({ swipe: swipe });
    if (isIE) {
      document.getElementById("scrollbars").style["z-index"] = swipe
        ? ""
        : "-1";
    }
  };

  isSwipeDisabed = () => (this.state.swipe ? false : true);

  handleChangeOrientation = () => {
    this.setState({ state: this.state });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);

    if (ga_active) {
      sendAnalyticsEvent(
        gaEventLabel.open_page,
        serverIndex !== null ? "0" : "1"
      );
    }

    const scrollable = document.querySelector(".Carousel");
    if (scrollable) scrollable.addEventListener("scroll", this.handleScroll);

    this.handleScroll();
    this.resize();
    if (!this.props.carouselIndex.firstLoad) {
      setTimeout(() => {
        this.props.setFirstLoad();
      }, 1000);
    }
    this.props.setRefZoom(this.prismaZoom);

    window.addEventListener("orientationchange", this.handleChangeOrientation);
    let pageIndex = this.props.setPageIndex;

    if (pageIndex !== undefined && !isNaN(pageIndex)) {
      pageIndex = parseInt(pageIndex);

      setTimeout(() => {
        this.props.setIndexCarousel(pageIndex);
      }, 100);
    } else {
      if (this.state.globalWidth < BREAKPOINT.lg) {
        pageIndex = 2;
      }
      if (this.state.leafleat_has_video) {
        if (this.state.pages_with_video.includes(1)) {
          this.showPageVideo(1, true);
        } else if (
          this.state.pages_with_video.includes(this.state.slideIndex)
        ) {
          this.showPageVideo(this.state.slideIndex, true);
        }
      }
    }
    setTimeout(() => window.dispatchEvent(new Event("resize")), 0);

    this.setState({
      loaded: true
    });

    this.timer();
  }

  timer = () => {
    if (this.timerHandle) {
      return;
    }
    this.timerHandle = setTimeout(() => {
      if (document.getElementById("plus-" + this.props.itemId)) {
        document.getElementById("plus-" + this.props.itemId).click();
      }
      this.timerHandle = 0;
    }, 100);
  };

  clearTimer = () => {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };

  setSwitchIndex = (n) => {
    const { carouselIndex } = this.props.carouselIndex;
    const has_index = serverIndex !== null;

    if (carouselIndex === 0) {
      this.props.setIndexCarousel(0);
    } else if (n === 1) {
      if (has_index) {
        this.props.setIndexCarousel(carouselIndex * 2);
      } else {
        this.props.setIndexCarousel(carouselIndex * 2 - 1);
      }
    } else {
      if (has_index) {
        this.props.setIndexCarousel(Math.floor(carouselIndex / 2));
      } else {
        let index =
          carouselIndex % 2 === 0
            ? Math.floor(carouselIndex / 2)
            : (carouselIndex + 1) / 2;
        this.props.setIndexCarousel(index);
      }
    }
  };

  resize() {
    this.setState({
      globalHeigth: window.innerHeight
    });
    this.setState({ globalWidth: window.innerWidth });

    if (!show_one_page) {
      if (
        this.state.globalWidth < BREAKPOINT.lg &&
        !this.state.isCurrentOnePage
      ) {
        this.setState({ isCurrentOnePage: true });
        this.setSwitchIndex(1);
      } else if (
        this.state.globalWidth >= BREAKPOINT.lg &&
        this.state.isCurrentOnePage
      ) {
        this.setState({ isCurrentOnePage: false });
        this.setSwitchIndex(2);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener(
      "orientationchange",
      this.handleChangeOrientation
    );
    this.clearTimer();
  }

  disableSwipe = () => {
    setTimeout(() =>
      this.setState({
        isZoom: true
      })
    );
  };

  enableSwipe = () => {
    this.setState({
      isZoom: false
    });
  };

  getThumb(x) {
    const hasIndex = serverIndex != null;
    const leafLeatLength = thumbs.length;
    const onePage = show_one_page || this.state.globalWidth < BREAKPOINT.lg;

    if (hasIndex) {
      if (onePage) {
        return thumbs[this.state.tmpIndex];
      } else {
        return x === 1
          ? thumbs[this.state.tmpIndex * 2]
          : this.state.tmpIndex * 2 + 1 > leafLeatLength
          ? null
          : thumbs[this.state.tmpIndex * 2 + 1];
      }
    } else {
      if (onePage) {
        return thumbs[this.state.tmpIndex];
      } else {
        if (this.state.tmpIndex === 0) {
          return x === 1 ? null : thumbs[this.state.tmpIndex * 2];
        } else {
          return x === 1
            ? thumbs[this.state.tmpIndex * 2 - 1]
            : this.state.tmpIndex * 2 + 1 > leafLeatLength
            ? null
            : thumbs[this.state.tmpIndex * 2];
        }
      }
    }
  }

  handleScroll = () => {
    const scrollable = document.querySelector(".Carousel");
    if (scrollable) {
      const scrollPosition =
        scrollable.scrollHeight -
        scrollable.scrollTop -
        scrollable.clientHeight;

      if (scrollPosition < 300) {
        this.props.setVerticalPages();
      }
    }
  };

  isLastPage = (pageNumber) => {
    return pageNumber === pages.length;
  };

  // loadMore = () => {
  //   var t_pages = [];
  //   // this.setState({ items: this.state.items + 10 });
  //   this.props.setVerticalPages()
  //   // serverResponse.leaflet.pages.map((element, key) => {
  //   //   if (key < this.state.items) t_pages.push(element.thumb_image_file);
  //   //   return 0;
  //   // });
  //   // this.setState({ pages: t_pages });
  // };

  render() {
    const pages = this.props.leaflet.pages.filter(
      (element, key) => element.number > 0
    );

    const imageUri = (el, key1, key2) => (isSupported ? el[key1] : el[key2]);

    // Slides
    var listButtonsPager = [];
    var max = 0;
    let listSlides = [];

    global.showOnePage = true;
    if (serverIndex !== null) {
      listSlides.push(
        <div key={0} className="row w-100 background_color">
          <div className="d-flex justify-content-center col-12 p-0">
            <CustomIndex index={this.props.leaflet.index} />
          </div>
        </div>
      );

      listButtonsPager.push(
        <Button
          disabled={this.props.carouselIndex.buttonCarouselDisabled}
          className="carousel-pagination-button"
          style={styles.pagerButtons}
          key={"index"}
          color="primary"
          onClick={() => {
            this.props.isFromInput(true);
            this.props.setIndexCarousel(0);
          }}
          active={this.props.carouselIndex.carouselIndex === 0}
        >
          Indice
        </Button>
      );

      max++;
    }

    pages
      .slice(0, this.props.carouselIndex.verticalPages)
      .map((page, index) => {
        listSlides.push(
          <div key={page.number} className="row w-100 background_color">
            <div className="d-flex justify-content-center col-12 p-0">
              <CustomSlide
                zoom={this.prismaZoom.current}
                disableSwipe={this.disableSwipe}
                enableSwipe={this.enableSwipe}
                pageNumber={page.number}
                imagePath={imageUri(page, "webp_image_file", "image_file")}
                imageThumbPath={imageUri(
                  page,
                  "webp_thumb_image_file",
                  "thumb_image_file"
                )}
                products={page.interactivities}
                leafletSwipe={this.disableSwipe}
                isSwipeDisabed={this.isSwipeDisabed}
                setSwipe={this.setSwipe}
              />
            </div>
          </div>
        );

        listButtonsPager.push(
          <Button
            disabled={this.props.carouselIndex.buttonCarouselDisabled}
            className="carousel-pagination-button"
            style={styles.pagerButtons}
            key={index}
            color="primary"
            onClick={() => {
              this.props.isFromInput(true);
              this.props.setIndexCarousel(
                serverIndex === null ? index : index + 1
              );
            }}
            active={
              serverIndex === null
                ? this.props.carouselIndex.carouselIndex === index
                : this.props.carouselIndex.carouselIndex === index + 1
            }
          >
            {index + 1}
          </Button>
        );

        max++;
        return true;
      });

    global.max = max;

    //Pagination

    const toggleSwiping = (e) => {
      this.setState({
        isZoom: e === 1 ? false : true
      });

      this.props.setZoomLevel(e);
    };

    const retrievePageAndCheckVideo = (str) => {
      let res = false;
      let page = null;
      if (!str || str === undefined || str === null) {
        return res;
      }
      if (str === "Indice") {
        str = "0";
      }
      if (this.state.globalWidth < BREAKPOINT.lg || show_one_page) {
        const pageToNumber = parseInt(str);
        if (this.state.pages_with_video.includes(pageToNumber)) {
          page = parseInt(pageToNumber);
          res = true;
        }
      } else {
        const labelPages = str.split("-");
        for (let index = 0; index < labelPages.length; index++) {
          if (
            this.state.pages_with_video.includes(parseInt(labelPages[index]))
          ) {
            page = parseInt(labelPages[index]);
            res = true;
          }
        }
      }
      if (res && page !== null) {
        const [{ url }] = video.videos.filter((pg) => pg.page === page);
        return { res, url };
      }

      return res;
    };

    let classPrismaZoom = adsense ? "H_adsense" : "H_not_adsense";
    if (checkMobileOs() || this.state.globalWidth < BREAKPOINT.lg) {
      classPrismaZoom = adsense
        ? "H_adsense_mobile_detected"
        : "H_not_adsense_mobile_detected";
    }

    const items = (
      <div className="col-12 p-2 h-100">
        <div className="row w-100 m-0 h-100">
          {/* CAROSELLO */}
          <div
            style={{
              flexDirection: "column",
              overflowY: "scroll"
            }}
            className="col-12 p-0  custom-col-12 h-100 Carousel d-flex align-items-center"
          >
            {listSlides}
          </div>

          {/* FINE CAROSELLO */}
        </div>
      </div>
    );

    return (
      <div
        className="container-fluid p-0"
        id="leaflet"
        style={styles.prismaZoomContainer}
      >
        <PrismaZoom
          doubleTouchMaxDelay={1}
          onZoomChange={(e) => {
            toggleSwiping(e);
          }}
          maxZoom={this.props.carouselIndex.modalOpen ? 1 : 5}
          ref={this.prismaZoom}
          className={`row w-100 m-0 prismaZoom ${classPrismaZoom}`}
        >
          {items}
        </PrismaZoom>
        {/* *****ADSENSE******** */}
        {adsense && (
          <div className="row w-100 p-0 m-0" style={styles.adsenseContainer}>
            <Adsense />
          </div>
        )}

        {video && (
          <Modal
            isOpen={
              retrievePageAndCheckVideo(this.props.carouselIndex.labelIndex)
                .res && !this.state.closeClick
            }
            style={videoModalStyles}
            ariaHideApp={false}
          >
            <div className="modal-page-video-container">
              {this.state.video_muted && (
                <button
                  onClick={this.toggleVideoMuted}
                  className="audio-page-video-button"
                >
                  <i className="fas fa-volume-mute" />
                  <span style={styles.videoTextAudio}>Attiva audio</span>
                </button>
              )}
              {this.state.show_close_video && (
                <button
                  onClick={this.userCloseVideo}
                  className="close-page-video-button"
                >
                  <i className="fas fa-times" />
                </button>
              )}
              {!this.state.show_close_video && this.state.countdown > 0 && (
                <button className="wait-page-video-button">
                  Sfoglia il{" "}
                  {type === "leaflet"
                    ? "volantino"
                    : type === "menu"
                    ? "menù"
                    : "catalogo"}{" "}
                  tra {this.state.countdown} secondi
                </button>
              )}

              <ReactPlayer
                className="react-page-player"
                url={
                  retrievePageAndCheckVideo(this.props.carouselIndex.labelIndex)
                    .url
                }
                playing={this.state.video_playing}
                width="100%"
                height="100%"
                onProgress={this.onVideoProgress}
                onStart={this.onVideoStart}
                onEnded={this.onVideoEnded}
                muted={true}
                playsinline={this.state.globalWidth < BREAKPOINT.lg}
                ref={this.ref}
                volume={1}
                controls={true}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps, {
  setIndexCarousel,
  setIndexLabel,
  setSlideMax,
  isFromInput,
  setFirstLoad,
  setZoomLevel,
  setRefZoom,
  setButtonDisabled,
  setVerticalPages
})(CustomCarouselVertical);
