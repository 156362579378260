import React from "react";
import { Helmet } from "react-helmet";

const CookieConsentBanner = () => {
  return (
    <Helmet>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/ccpa/stub.js"
      ></script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        charset="UTF-8"
        async
      ></script>
    </Helmet>
  );
};

export default CookieConsentBanner;
