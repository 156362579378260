import React, { Component } from "react";
import "../../assets/css/product.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Tooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import Facebook from "../../assets/images/icons/facebook.svg";
import Whatsapp from "../../assets/images/icons/whatsapp.svg";
import ShowPriceMarker from "../../components/leaflet/markers/ShowPriceMarker";
import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import { isMobile, isIOS } from "react-device-detect";
import { NotificationManager } from "react-notifications";
import { serverResponse } from "../../util/fakeServer";
import { strings } from "../../util/data/strings";
import { gaEventLabel } from "../../settings/gaConfig";
import { styles } from "./product.style";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage, sendAnalyticsEvent } from "../../helper/utility";
import VideoInteractivity from "../leaflet/videoInteractivity/VideoInteractivity";
import Marker from "../leaflet/markers/Marker";
import TitleModal from "../modal/modalbody/components/Title.modal";
import GalleryModal from "../modal/modalbody/components/Gallery.modal";
import Price from "../modal/modalbody/components/Price.modal";
import AddIcon from "../common/AddIcon";

const productUrl = serverResponse.config.shareFlyerURL;

class Product extends Component {
  state = {
    itemCarousel: 0,
    varietySelected:
      this.props.info && this.props.info.varieties
        ? this.props.info.varieties[0]
        : "",
    saveTooltipOpen: false,
    tooltipPricesOpen: false
  };

  componentWillReceiveProps(props) {
    this.setState({
      quantity: props.info.quantity_step ? props.info.quantity_step : 1,
      varietySelected: props.info.varieties ? props.info.varieties[0] : ""
    });
  }

  componentDidMount() {
    this.setState({
      quantity: this.props.info.quantity_step
        ? this.props.info.quantity_step
        : 1
    });
  }

  navMarkers = (newMarker) => {
    var element = document.getElementById(
      newMarker + "-" + this.props.info.item_id
    );
    element.click();
  };

  incrementQty = () => {
    this.setState({
      quantity:
        this.state.quantity +
        (this.props.info.quantity_step ? this.props.info.quantity_step : 1)
    });
  };

  decrementQty = () => {
    if (this.props.info.quantity_step) {
      if (this.state.quantity > this.props.info.quantity_step) {
        this.setState({
          quantity: this.state.quantity - this.props.info.quantity_step
        });
      }
    } else if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  shareOnFacebook = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_facebook,
      String(this.props.info.item_id)
    );
    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  shareOnWhatsapp = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_whatsapp,
      String(this.props.info.item_id)
    );
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  handleAddProduct = () => {
    let obj = Object.assign({}, this.props.info);
    obj.quantity = this.state.quantity;
    obj.done = false;
    obj.variety =
      !this.state.varietySelected || this.state.varietySelected === undefined
        ? ""
        : this.state.varietySelected;
    this.props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );

    if (serverResponse.config.ga_active) {
      sendAnalyticsEvent(
        gaEventLabel.add_to_grocery_list,
        String(this.props.info.item_id)
      );
      sendAnalyticsEvent(
        gaEventLabel.open_product,
        String(this.props.info.item_id)
      );
    }
  };

  handleChangeVariety = (e) => {
    this.setState({
      varietySelected: e.target.value
    });
  };

  toggleSaveTooltip = () => {
    this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
  };

  toggleTooltipPricesOpen = () => {
    this.setState({ tooltipPricesOpen: !this.state.tooltipPricesOpen });
  };

  render() {
    const { info } = this.props;
    let markers = this.props.info.markers.map((element, index) => {
      if (
        this.props.info.type === "video" &&
        !this.props.info.markers[0].data.open_modal
      ) {
        return (
          <VideoInteractivity
            key={index}
            info={this.props.info}
            markerInfo={element}
            leafletSwipe={this.props.leafletSwipe}
            type={element.type}
          />
        );
      }
      return (
        <Marker
          // zoom={this.props.zoom}
          // disableSwipe={this.props.disableSwipe}
          // enableSwipe={this.props.enableSwipe}
          key={index}
          info={this.props.info}
          markerInfo={element}
          leafletSwipe={this.props.leafletSwipe}
          // navMarkers={this.navMarkers}
          // setSwipe={this.props.setSwipe}
          // isSwipeDisabed={this.props.isSwipeDisabed}
          type={element.type}
        />
      );
    });

    const stock = {
      y: {
        text: "Disponibile",
        color: "green",
        icon: <i className="fas fa-check"></i>
      },
      n: {
        text:
          "Non disponibile" +
          (this.props.info.tdc !== null
            ? " - arrivo tra " + this.props.info.tdc + " giorni"
            : ""),
        color: "red",
        icon: <i className="fas fa-times"></i>
      },
      c: {
        text: this.props.info.available_from,
        color: "var(--secondary-color)",
        icon: <i className="far fa-clock"></i>
      }
    };

    var disp = stock[this.props.info.stock];

    // eslint-disable-next-line no-unused-vars
    let hidden_price_marker = [];
    if (this.props.info.hidden_price) {
      hidden_price_marker = this.props.info.markers.map((element, key) => {
        switch (element.type) {
          case "play": {
            return (
              <ShowPriceMarker
                showButton={true}
                key={key + 10}
                info={info}
                markerInfo={element}
                leafletSwipe={() => {}}
                navMarkers={this.navMarkers}
                setSwipe={() => {}}
              />
            );
          }
          default:
            return null;
        }
      });
    }

    let varieties = <Row className="varieties-container"></Row>;
    if (this.props.info.varieties.length > 1) {
      if (this.props.info.varieties.length > 0) {
        varieties = (
          <Row style={{ minHeight: "0px", marginBottom: "15px" }}>
            <Col md="12">
              {/* <Label for="variety">Scegli:</Label>*/}
              <InputGroup>
                {/*    <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>*/}
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  style={{ borderRadius: "6px" }}
                  type="select"
                  name="select"
                  id="variety"
                  value={this.state.varietySelected}
                  onChange={this.handleChangeVariety}
                >
                  {this.props.info.varieties.map((element, key) => {
                    return (
                      <option key={key} value={element}>
                        {element}
                      </option>
                    );
                  })}
                </Input>
              </InputGroup>
            </Col>
          </Row>
        );
      }
    }

    // eslint-disable-next-line no-unused-vars
    let brandImg = "";
    if (
      serverResponse.config.brandImage !== "" &&
      this.props.info.pam === true
    ) {
      brandImg = (
        <img
          style={{ width: "30%" }}
          src={process.env.PUBLIC_URL + serverResponse.config.brandImage}
          alt="img"
        />
      );
    }

    // eslint-disable-next-line no-unused-vars
    let cardImg = "";
    if (
      serverResponse.config.underPriceImage !== "" &&
      serverResponse.config.underPriceImage !== null &&
      this.props.info.underpriced_product === true
    ) {
      cardImg = (
        <img
          style={{ width: "50%", marginTop: "10px" }}
          src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage}
          alt="img"
        />
      );
    }

    let h6Class = "";
    let isLineThrough = false;
    if (Boolean(serverResponse.config.line_through) === true) {
      h6Class = "barrato";
      isLineThrough = true;
    }

    return (
      <div className="ptb-15 h-100">
        <div className="roe-card-style h-100 d-flex flex-column">
          <div
            className="roe-card-header custom-card-header"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            {/* markers e condivisione */}
            <div className="row">
              <div className="col-12">
                <div className="markers-container markers-container-no-margin">
                  {markers}

                  {this.props.info.stock !== null &&
                    this.props.info.stock !== undefined &&
                    serverResponse.config.manager_stock && (
                      <span style={styles.available(disp.color, isMobile)}>
                        {disp.icon} {disp.text}
                      </span>
                    )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Facebook}
                      className="share-icon"
                      style={{ marginLeft: "auto" }}
                      alt="facebook"
                      onClick={() => this.shareOnFacebook()}
                    />
                  )}
                  {!serverResponse.config.hide_share_product && (
                    <img
                      src={Whatsapp}
                      className="share-icon"
                      alt="whatsapp"
                      onClick={() => this.shareOnWhatsapp()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="roe-card-body d-flex flex-column h-100">
            <div className="row h-100 w-100 m-0">
              <div className="w-100 d-flex">
                <div className="row w-100 m-0 p-0">
                  <div className="col-6 p-0">
                    <div className="col-12 container-fields mb-3 p-0">
                      {/* Testi */}
                      <TitleModal fontSize="16px" title={info.field1} />
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textTransform: "uppercase"
                        }}
                      >
                        {info.field2} {info.field3} {info.field4}
                      </p>
                      {/* <TextModal
                        text1={info.field2}
                        varieties={info.varieties}
                      />
                      <TextModal text1={info.field3} text2={info.field4} /> */}
                    </div>

                    <Price
                      col={false}
                      fontSize="1.5rem"
                      textAlign="left"
                      styleContainer={{ padding: 0 }}
                      {...info}
                    />
                  </div>
                  <div className="col-6 container-fields text-right p-0">
                    <GalleryModal
                      customClassContainer={"m-0"}
                      customClassCol={"p-0"}
                      maxWidth={"220px"}
                      gallery={[info.images[0]]}
                      arrows={false}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <GalleryModal maxWidth={"220px"} gallery={info.images} />
              </div> */}

              <div className="d-flex w-100 align-self-end">
                <div
                  className={
                    info.focus || info.one_and_one_gratis
                      ? "col-12"
                      : "col-lg-8 col-md-6"
                  }
                  // md={info.focus || info.one_and_one_gratis ? 12 : 6}
                  // lg={info.focus || info.one_and_one_gratis ? 12 : 8}
                  // style={{ minHeight: "140px" }}
                >
                  <div className="product-price">
                    {isLineThrough && info.field4 !== "" && (
                      <div className={h6Class} style={{ fontSize: "14px" }}>
                        {info.field4}
                      </div>
                    )}
                    {isLineThrough && info.field4 === "" && (
                      <div style={{ fontSize: "14px" }}>&nbsp;</div>
                    )}
                    {/* {info.offer_price !== null && (
                    <span
                      className="barrato mr-3"
                      style={{ fontSize: "15px", color: "grey" }}
                    >
                      &euro;{info.price.replace(".", ",")}
                    </span>
                  )} */}
                  </div>
                  <div>{varieties}</div>
                  {!info.focus &&
                    !info.one_and_one_gratis &&
                    !serverResponse.config.hide_grocery_list && (
                      <div
                        className="container-add-to-list container-add-to-list-color"
                        style={{
                          borderRadius: "6px",
                          visibility: this.props.info.hidden_price
                            ? "hidden"
                            : ""
                        }}
                      >
                        <FontAwesomeIcon
                          className="change-product-quantity-icon"
                          icon={faMinusCircle}
                          onClick={() => this.decrementQty()}
                        />
                        <h6>
                          {this.state.quantity}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {info.grocery_label}
                          </span>
                        </h6>
                        <FontAwesomeIcon
                          className="change-product-quantity-icon"
                          icon={faPlusCircle}
                          onClick={() => this.incrementQty()}
                        />
                      </div>
                    )}
                </div>
                {!info.focus &&
                  !info.one_and_one_gratis &&
                  !serverResponse.config.send_grocery_list_to_market &&
                  !serverResponse.config.hide_grocery_list && (
                    <div
                      className="col-6 col-lg-4 align-self-end"
                      // md={{ size: 4, offset: 2 }}
                      // lg={{ size: 4, offset: 0 }}
                    >
                      <div
                        className=""
                        style={{
                          justifyContent: "flex-end",
                          textAlign: "end"
                        }}
                      >
                        <div className="d-flex  justify-content-end width">
                          <AddIcon
                            // backgroundColor={"black"}
                            className="button-send-to-cart"
                            id={"btnSave_" + info.item_id}
                            onClick={this.handleAddProduct}
                            width={35}
                          />
                        </div>

                        {!isMobile &&
                          (this.props.info.stock === "y" ||
                            this.props.info.stock === undefined) && (
                            <Tooltip
                              placement="top"
                              isOpen={this.state.saveTooltipOpen}
                              target={"btnSave_" + info.item_id}
                              toggle={this.toggleSaveTooltip}
                            >
                              {strings.saveBtnTooltip}
                            </Tooltip>
                          )}
                      </div>
                    </div>
                  )}
                {!info.focus &&
                  !info.one_and_one_gratis &&
                  serverResponse.config.send_grocery_list_to_market &&
                  !serverResponse.config.hide_grocery_list && (
                    <div
                      className="col-6 col-lg-4 align-self-end"
                      // md={{ size: 4, offset: 2 }}
                      // lg={{ size: 4, offset: 0 }}
                    >
                      <div
                        className=""
                        style={{
                          justifyContent: "flex-end",
                          textAlign: "end"
                        }}
                      >
                        <div className="d-flex  justify-content-end width">
                          <AddIcon
                            // backgroundColor={"black"}
                            className="button-send-to-cart"
                            id={"btnSave_" + info.item_id}
                            onClick={this.handleAddProduct}
                            width={35}
                          />
                        </div>

                        {!isMobile &&
                          (this.props.info.stock === "y" ||
                            this.props.info.stock === undefined) && (
                            <Tooltip
                              placement="top"
                              isOpen={this.state.saveTooltipOpen}
                              target={"btnSave_" + info.item_id}
                              toggle={this.toggleSaveTooltip}
                            >
                              {strings.saveBtnTooltip}
                            </Tooltip>
                          )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    }
  };
}

export default connect(null, mapDispatchToProps)(Product);
