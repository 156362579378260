import {
  OPEN_INTERACTIVITY_MODAL,
  CLOSE_INTERACTIVITY_MODAL,
  OPEN_RELATED
} from "../type";

const initState = {
  isModalOpen: false,
  contentModal: {},
  otherProps: {},
  loading: false
};

export default function(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case OPEN_INTERACTIVITY_MODAL:
      return {
        ...state,
        isModalOpen: true,
        otherProps: payload,
        contentModal: payload.info
      };
    case OPEN_RELATED:
      return {
        ...state,
        contentModal: payload.content
      };
    case CLOSE_INTERACTIVITY_MODAL:
      return initState;
    default:
      return state;
  }
}
