import axios from "axios";

const url = "https://viewmanager.interattivo.net/api/analitic/";
// const url = "http://192.168.1.47/viewmanager/api/analitic/";

const API = axios.create({
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

export const visit = (data) => API.post("visit", data);

export const sendEvent = (data) => API.post("event", data);

export const sendEventVerticalPage = (data) =>
  API.post("open-vertical-pages", data);
