export const styles = {
  sidebarContainer: {
    content: {
      backgroundColor: "white",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      transitionDuration: "500ms",
      height: "100vh",
      zIndez: "9999"
    },
  },
  categoryContainer: {
    height: "75%",
    marginTop: "20px",
  },
  resultContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: "15px",
  },
  categoryName: (color) => {
    return {
      fontWeight: "bold",
      color,
    };
  },
  subCategoryName: (color) => {
    return {
      marginLeft: "10px",
      color,
    };
  },
  boldClass: {
    fontWeight: "bold",
  },
  marginBottomClass: {
    marginBottom: "10px",
  },
  buttonScrollUpBtn: (display, bgColor, size = 45) => {
    return {
      padding: "7px",
      borderRadius: "100%",
      display: display,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: bgColor,
      width: `${size}px`,
      height: `${size}px`,
      fontSize: "23px",
      border: "1px solid grey",
      color: "black",
    };
  },
  filterScrollUp: (bgColor, marginB, size = 45) => {
    return {
      padding: "15px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: bgColor,
      bottom: `${marginB}px`,
      transitionDuration: "400ms",
      width: `${size}px`,
      height: `${size}px`,
    };
  },
  displayType: (typeDisplay = "block") => {
    return {
      display: typeDisplay,
    };
  },
};
