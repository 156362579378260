import React, { useState } from "react";
import { serverResponse } from "../../../util/fakeServer";
import BrandModal from "./components/Brand.modal";
import GalleryModal from "./components/Gallery.modal";
import TextModal from "./components/Text.modal";
import TitleModal from "./components/Title.modal";
import UnderPriceImgModal from "./components/UnderPriceImg.modal";
import PriceModal from "./components/Price.modal";
import AddCart from "./components/AddCart.modal";
import ReadMore from "../../common/ReadMore";
import VarietiesModal from "./components/Varieties.modal";
import Related from "./components/Related.modal";
import { connect } from "react-redux";
import useCurrentWidth from "../../../hooks/widthHook";
import { BREAKPOINT } from "../../../helper/constant";

const PlusBody = ({ modalRedux }) => {
  const { contentModal: info } = modalRedux;
  const [varietySelected, setVarietySelected] = useState("");
  const currentWidth = useCurrentWidth();

  return (
    <>
      {/* Galleria immagini */}

      <div
        className="col-8 col-md-12 col-lg-6 m-auto"
        style={{ position: "relative" }}
      >
        <GalleryModal dots={true} autoplay={true} gallery={info.images} />
      </div>

      <div className="col-12 col-lg-6 ">
        {/* Aggiunta al carrello */}

        {info.varieties.length > 0 && (
          <div className="col-12 d-md-none">
            <VarietiesModal
              varieties={info.varieties}
              handleChangeVariety={setVarietySelected}
            />
          </div>
        )}
        {!serverResponse.config.hide_grocery_list &&
          currentWidth <= BREAKPOINT.lg && (
            <div className="d-flex justify-content-between row">
              <AddCart
                customClassContainer="w-100"
                colCustom={"col-6"}
                justifyContent={"flex-start"}
                setVarietySelected={setVarietySelected}
                varietySelected={varietySelected}
                info={info}
                price={true}
                componentPrice={
                  <PriceModal
                    styleContainer={{
                      textAlign: "right",
                      whiteSpace: "nowrap",
                      padding: 0
                    }}
                    colCustom={"col-6"}
                    fontSize="1.55rem"
                    styleParagraph={"15px"}
                    strike_price={info.strike_price}
                    price={info.price}
                    offer_price={info.offer_price}
                    discount_rate={info.discount_rate}
                    price_label={info.price_label}
                    no_price={info.no_price}
                  />
                }
              />
            </div>
          )}
        {/* field1 */}
        <div className="row custom-flex-dir">
          <div className="col-12">
            <TitleModal fontSize={"1rem"} title={info.field1} />

            {/* field2 */}
            <TextModal text1={info.field2} varieties={info.varieties} />

            {/* field 3 field4 */}
            {(!info.brand_logo ||
              info.brand_logo === undefined ||
              info.brand_logo === null) && (
              <TextModal text1={info.field3} text2={info.field4} />
            )}

            {/* Brand logo */}
            {info.brand_logo && (
              <BrandModal
                brand={info.brand}
                brand_logo={info.brand_logo}
                line={info.line}
              />
            )}

            {/* Immagine underprice */}
            {serverResponse.config.underPriceImage !== "" &&
              serverResponse.config.underPriceImage !== null &&
              this.props.info.underpriced_product === true && (
                <UnderPriceImgModal
                  underPriceImage={serverResponse.config.underPriceImage}
                />
              )}

            {/* Prezzo */}
            {currentWidth > BREAKPOINT.lg ? (
              <div className="col-6 col-lg-12">
                <div className="row ">
                  <PriceModal
                    styleContainer={{ textAlign: "left", padding: 0 }}
                    strike_price={info.strike_price}
                    price={info.price}
                    offer_price={info.offer_price}
                    discount_rate={info.discount_rate}
                    price_label={info.price_label}
                    no_price={info.no_price}
                  />
                </div>
              </div>
            ) : null}
            {/* Descrizione estesa */}
            {info.descrizione_estesa !== undefined &&
              info.descrizione_estesa !== "" && (
                <div className="row">
                  <div
                    className="col-12 wrapText"
                    style={{ overflowWrap: "break-word" }}
                  >
                    <ReadMore>{info.descrizione_estesa}</ReadMore>
                  </div>
                </div>
              )}

            {/* Varietà */}
            {info.varieties.length > 0 && (
              <div className="d-none d-md-block">
                <VarietiesModal
                  varieties={info.varieties}
                  handleChangeVariety={setVarietySelected}
                />
              </div>
            )}
            {!serverResponse.config.hide_grocery_list &&
              currentWidth > BREAKPOINT.lg && (
                <AddCart
                  justifyContent={"flex-end"}
                  setVarietySelected={setVarietySelected}
                  varietySelected={varietySelected}
                  info={info}
                />
              )}
          </div>
          {/* {currentWidth <= BREAKPOINT.lg && (
            <div className="col-12">
              <div className="row ">
                <PriceModal
                  styleContainer={{
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    padding: 0
                  }}
                  fontSize="1.55rem"
                  styleParagraph={"15px"}
                  strike_price={info.strike_price}
                  price={info.price}
                  offer_price={info.offer_price}
                  discount_rate={info.discount_rate}
                  price_label={info.price_label}
                  no_price={info.no_price}
                />
              </div>
            </div>
          )} */}
        </div>
      </div>

      <div className="col-12">
        {/* Prodotti correlati */}
        {info.related.length > 0 && <Related related={info.related} />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};

export default connect(mapStateToProps)(PlusBody);
