import React from "react";
import { connect } from "react-redux";
import ReadMore from "../../common/ReadMore";
import GalleryModal from "./components/Gallery.modal";
import TitleModal from "./components/Title.modal";

const InfoBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { data }
    }
  } = modalRedux;

  return (
    <>
      <div className="col-6  col-lg-6 m-auto" style={{ position: "relative" }}>
        <GalleryModal gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6">
        <TitleModal title={data.titolo} />
        <div className="wrapText">
          <ReadMore>{data.testo}</ReadMore>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};
export default connect(mapStateToProps)(InfoBody);
