import {
  SET_INDEX_LABEL,
  CAROUSEL_LOADING,
  SET_CAROUSEL_INDEX,
  SET_BUTTON_DISABLED,
  IS_FROM_INPUT,
  SET_FIRST_LOAD,
  SET_ZOOM_LEVEL,
  SET_REF_ZOOM,
  IS_MODAL_OPEN,
  IS_RIGHT_SIDEBAR_OPEN,
  SET_VERTICAL_PAGES
} from "../type";
import {
  defaultSlide,
  defaultState,
  setLabelSlider
} from "../../helper/utility";

const initState = defaultState();
export default function(state = initState, action) {
  switch (action.type) {
    case CAROUSEL_LOADING:
      return {
        ...state,
        loading: true
      };

    case SET_CAROUSEL_INDEX:
      return {
        ...state,
        loading: false,
        carouselIndex: action.payload,
        buttonCarouselDisabled: false,
        labelIndex: setLabelSlider(action.payload)
      };
    case SET_INDEX_LABEL:
      return {
        ...state,
        loading: false,
        labelIndex: setLabelSlider(action.payload)
      };
    case SET_BUTTON_DISABLED:
      return {
        ...state,
        buttonCarouselDisabled: action.payload
      };
    case IS_FROM_INPUT: {
      return {
        ...state,
        fromInput: action.payload
      };
    }
    case SET_FIRST_LOAD: {
      return {
        ...state,
        firstLoad: true
      };
    }
    case SET_ZOOM_LEVEL: {
      return {
        ...state,
        zoomValue: action.payload
      };
    }
    case SET_REF_ZOOM: {
      return {
        ...state,
        refZoom: action.payload
      };
    }
    case IS_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: action.payload
      };
    }
    case IS_RIGHT_SIDEBAR_OPEN: {
      return {
        ...state,
        sidebarRightOpen: action.payload
      };
    }
    case SET_VERTICAL_PAGES: {
      return {
        ...state,
        verticalPages: state.verticalPages + defaultSlide
      };
    }

    default:
      return state;
  }
}
