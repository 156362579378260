import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { styles } from "./zoomButton.style";
import { withRouter } from "react-router-dom";

const ZoomButton = ({ carouselIndex, history }) => {
  const zoomIn = () => {
    carouselIndex.refZoom.current.zoomIn(1);
  };
  const zoomOut = () => {
    carouselIndex.refZoom.current.zoomOut(1);
  };

  return (
    <div id="zoom-btns" style={styles.buttons_wrapper}>
      <div style={{ textAlign: "right", display: "flex" }}>
        <Button
          className="carousel-pagination-button"
          disabled={carouselIndex.zoomValue === 1}
          onClick={zoomOut}
          style={styles.button}
        >
          <i className="fas fa-search-minus"></i>
        </Button>
        <Button
          className="carousel-pagination-button"
          disabled={carouselIndex.zoomValue === 5}
          onClick={zoomIn}
          style={styles.button}
        >
          <i className="fas fa-search-plus"></i>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default withRouter(connect(mapStateToProps)(ZoomButton));
