import {
  OPEN_INTERACTIVITY_MODAL,
  CLOSE_INTERACTIVITY_MODAL,
  OPEN_RELATED
} from "../type";

export const openInteractivityModal = (obj) => (dispatch) => {
  dispatch({
    type: OPEN_INTERACTIVITY_MODAL,
    payload: obj
  });
};

export const openRelated = (content) => (dispatch) => {
  dispatch({
    type: OPEN_RELATED,
    payload: content
  });
};

export const closeInteractivityModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_INTERACTIVITY_MODAL
  });
};
