import React from 'react'

const Brand = ({ brand, brand_logo, line }) => {

    return (
        <>
            <img
                className="float-left"
                src={brand_logo}
                alt={brand}
            />
            <span style={{fontSize: "15px"}}>{line}</span>
        </>
    )
}

export default Brand