import React from "react";
import { connect } from "react-redux";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";

const SpecsBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { data }
    }
  } = modalRedux;

  return (
    <>
      <div className="col-6 col-lg-6" style={{ position: "relative" }}>
        <GalleryModal gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6">
        {/* <TitleModal title={title} /> */}

        <ListModal list={data.specifiche} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};
export default connect(mapStateToProps)(SpecsBody);
