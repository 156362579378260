import React, { useEffect, useState } from "react";
import { Label, InputGroup, InputGroupAddon } from "reactstrap";
import { serverResponse } from "../../../../util/fakeServer";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Varieties = ({ varieties, handleChangeVariety }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    formattedOptions();
  }, []);

  const formattedOptions = () => {
    let opt = [];
    varieties.map((element) => opt.push({ value: element, label: element }));
    setOptions(opt);
  };

  return (
    <>
      <div className="row" style={{ marginTop: "30px" }}>
        <div className="col-12">
          <Label for="variety">
            {serverResponse.config.hide_grocery_list ? "Varianti" : "Scegli"}:
          </Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <span className="input-group-text icon-input-group">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </InputGroupAddon>
            {options.length > 0 && (
              <Select
                menuPlacement="top"
                styles={{ zIndex: 10 }}
                isSearchable={false}
                className="select-variety"
                defaultValue={options[0]}
                onChange={(val) => handleChangeVariety(val.value)}
                options={options}
                maxMenuHeight={200}
              />
            )}
          </InputGroup>
        </div>
      </div>
    </>
  );
};

export default Varieties;
