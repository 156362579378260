import React, { memo, useRef } from "react";
import { serverResponse } from "../../../../util/fakeServer";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useCurrentWidth from "../../../../hooks/widthHook";
import { BREAKPOINT } from "../../../../helper/constant";

const GalleryModal = ({
  gallery,
  maxWidth = "350px",
  customClassContainer = "",
  customClassCol = "col-lg-10",
  dots = false,
  autoplay = false,
  arrows = true
}) => {
  const currentWidth = useCurrentWidth();

  const isLg = currentWidth > BREAKPOINT.lg;

  const settings = {
    dots: isLg || !dots ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: gallery.length > 1 && isLg && arrows ? true : false,
    autoplay: autoplay,
    autoplaySpeed: 2000
  };

  const slickRef = useRef();
  const listImages = gallery.map((element, key) => (
    <div key={key}>
      <img
        // style={{
        //   maxHeight: "350px",
        //   maxWidth: maxWidth
        // }}
        className="m-auto img-fluid"
        loading="lazy"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = `${serverResponse.config.clientIcon}`;
        }}
        src={
          element.image_file
            ? process.env.PUBLIC_URL +
              element.image_file +
              "?v=" +
              serverResponse.config.version
            : element
        }
        alt="img"
      />
    </div>
  ));
  return (
    <div className={`${customClassContainer} row`}>
      <div
        className={`${customClassCol} col-12  m-auto  justify-content-center align-items-center`}
      >
        <Slider style={{ width: "100%" }} ref={slickRef} {...settings}>
          {listImages}
        </Slider>
      </div>
    </div>
  );
};

export default memo(GalleryModal);
